<template>
    <div>
        <van-popup v-model="show" :close-on-click-overlay="true" @close="ww">
            <div style="width: 18rem;margin: 2rem 0rem 2rem 0rem;font-size: 12px;">
                <div style="text-align: center;font-size: 14px;font-weight: 700;">注册成为推广员</div>
                <div style="text-align: center;font-size: 12px;margin-bottom: 1rem;">实时获取收益</div>
                <div style="width:80%;text-align: center;margin: auto;">
                    <van-form @submit="onSubmit">
                        <van-field
                                v-model="username"
                                name="username"
                                label="姓名"
                                placeholder="真实姓名"
                                :rules="[{ required: true, message: '请填写' }]"
                                maxlenght="10"
                                required
                        />
                        <van-field
                                v-model="channel"
                                name="channel"
                                label="渠道码"
                                placeholder="邀请码/渠道码"
                                maxlenght="10"
                        />
                        <div style="font-size: 10px;text-align: right;color: #ccc;padding-right: 46px;">*如果没有则不填写</div>
                        <div style="margin: 16px;">
                            <van-button round block type="danger" native-type="submit" size="small">
                                开启赚钱模式
                            </van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    created(){
        //获取凭证
        let is_agent = window.localStorage.getItem("is_agent")
        if(is_agent == 0 || is_agent == null){
            this.show = true
        }else{
            this.show = false
        }
    },
    data(){
        return{
            show:true,
            username:'',
            channel:''
        }
    },
    methods:{
        onSubmit(){
            axios.get(
                "reg_agent",{
                    params:{
                        name:this.username,
                        channel_id:this.channel
                    }
                }

            ).then((result) => {
              if(result.code == 1){
                  this.show  = false
                  window.localStorage.setItem("is_agent",1)
                  this.$router.push({path:'/agent'})
              } else if (result.message === "已注册推广员") {
                  this.$toast(result.message);
                  window.localStorage.setItem("is_agent",1)
                  this.$router.push({path:'/agent'})
              }
              else{
                  this.$toast(result.message)
              }
            })
        },
        ww(){
            this.$emit("tets",this.show)
        }
    },
}
</script>

<style>

</style>