<template>
    <div>
        <div class="header">
            <div class="preson">
                <van-image round width="66" height="66" :src="pageData.headimgurl"/>
                <div class="data">
                    <p>{{ pageData.nickname }}</p>
                    <div>
                        <span>K币 {{ pageData.coin_num }}</span>
                        <div class="discount-coupon" @click="$router.push('/coupons')">
                            <div class="divimg"><img src="/img/youhui.png" alt=""></div>
                            <div class="text1">优惠券 {{ pageData.coupon_num }} ></div>
                            <!--  {{ pageData.coin_num }}  -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-row class="user-links">
            <van-col span="6" is-link>
                <router-link to="/my?active=0" class="link">
                    <img src="https://khome2.tuzuu.com/klfront/center/icon1.png"/>
                    <span>全部</span>
                </router-link>
            </van-col>


            <van-col span="6">
                <router-link to="/my?active=1" class="link">
                    <img src="https://khome2.tuzuu.com/klfront/center/icon2.png"/>
                    <span>待支付</span>
                </router-link>
            </van-col>
            <van-col span="6">
                <router-link to="/my?active=2" class="link">
                    <img src="https://khome2.tuzuu.com/klfront/center/icon3.png"/>
                    <span>已支付</span>
                </router-link>
            </van-col>
            <van-col span="6">
                <router-link to="/my?active=4" class="link">
                    <img src="https://khome2.tuzuu.com/klfront/center/icon4.png"/>
                    <span>已取消</span>
                </router-link>
            </van-col>
        </van-row>

        <van-cell-group class="user-group">
            <van-cell icon="https://khome2.tuzuu.com/klfront/center/icon9.png" title="我的参观团" is-link to="/visit"/>
            <van-cell icon="https://khome2.tuzuu.com/klfront/center/icon5.png" title="我的订单" is-link to="/my"/>
            <van-cell icon="https://khome2.tuzuu.com/klfront/center/icon8.png" title="K币明细" is-link to="/kcoin"/>
            <van-cell icon="https://khome2.tuzuu.com/klfront/center/icon6.png" title="收货地址" is-link to="/addrlist"/>
            <!-- <van-cell icon="https://khome2.tuzuu.com/klfront/center/icon7.png" title="推广中心" is-link @click="href" /> -->
        </van-cell-group>

        <tab-bar :active="3"></tab-bar>
        <age-dialog v-if="show" @tets="change"></age-dialog>
    </div>
</template>

<script>
import AgeDialog from '@/components/AgeDialog'
import TabBar from '@/components/tabbar'

export default {
    data() {
        return {
            show: false,
            pageData: {}
        }
    },
    components: {
        TabBar,
        AgeDialog
    },
    methods: {
        href() {
            this.show = true
            if (window.localStorage.getItem('is_agent') == 1) {
                this.$router.push({path: '/agent'})
            }
        },
        change(state) {
            this.show = state
        }
    },
    async created() {
        const res = await axios.get('/user_center')
        console.log(res)
        this.pageData = res.data
    }
}
</script>

<style scoped lang="less">
.header {
    width: 100%;
    height: 230px;
    background-image: url('https://khome2.tuzuu.com/klfront/center/1.png?v=1');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .preson {
        padding-top: 50px;
        box-sizing: border-box;
        padding-left: 20px;
        display: flex;
        align-items: center;

        .data {
            margin-left: 10px;
            display: flex;
            height: 66px;
            flex-direction: column;
            justify-content: space-evenly;
            font-family: PingFang-SC-Medium, PingFang-SC;
            color: #ffffff;
            font-size: 14px;

            p:first-child {
                font-size: 18px;
            }

            .discount-coupon {
                position: absolute;
                right: 0px;
                top: 88px;
                background: #07a57d;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px 0px 0px 50px;
                padding-bottom: 4px;
                padding-top: 4px;

                .divimg {
                    margin-left: 8.5px;

                    img {
                        width: 17px;
                        height: 14px;
                    }
                }

                .text1 {
                    padding-bottom: 2px;
                    margin-left: 8px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}

.van-cell {
    padding: 19px 20px;

    /deep/ .van-icon__image {
        width: 25px;
        height: 25px;
        margin-right: 7px;
    }
}

.user-links {
    padding: 20px 0;
    color: #323233;
    font-size: 12px;
    background-color: #fff;
    text-align: center;
    width: calc(100% - 20px);
    margin: -80px auto 20px;
    border-radius: 16px;
    box-shadow: 0px 6px 16px 1px rgba(228, 228, 228, 0.61);
    img{
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 auto 8px;
    }
    //.van-icon {
    //    margin-bottom: 8px;
    //}
}

</style>>
